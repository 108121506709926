<template>
  <div
    class="flex bg-white py-16 mobile:flex-col medium:flex-wrap w-full flex-col px-64 mobile:px-0"
  >
    <div class="flex justify-center">
      <h1 class="text-5xl mb-8 mobile:text-4xl mobile:px-8 mobile:text-center">
        <span class="text-green">Suur kasu</span>. Väike kulu.
      </h1>
    </div>
    <div class="flex items-start mobile:flex-col">
      <div class="process-widget-tab">
        <div class="process-widget-tab-icon">
          <img src="/icons/process-widget/step2.svg" class="h-32" />
        </div>
        <h3 class="mt-4 font-semibold">Säästad aega</h3>
        <div class="process-widget-tab-separator"></div>
        <div class="process-widget-tab-content">
          Gewodos käib kõik töö lisamisest kuni arve väljastamiseni mõne klikiga
          - säästad kümneid tunde mõttetutelt pisiasjadelt ja paberimajanduselt.
        </div>
      </div>
      <div class="process-widget-tab">
        <div class="process-widget-tab-icon">
          <img src="/icons/process-widget/step1.svg" class="h-32" />
        </div>
        <h3 class="mt-4 font-semibold">Saad kasvada</h3>
        <div class="process-widget-tab-separator"></div>
        <div class="process-widget-tab-content">
          Kui lisandub rohkem töötajaid, projekte ja ülesandeid, siis Gewodoga
          ei tõuse su korralduse ajakulu märkimisväärselt.
        </div>
      </div>
      <div class="process-widget-tab">
        <div class="process-widget-tab-icon">
          <img src="/icons/process-widget/step3.svg" class="h-32" />
        </div>
        <h3 class="mt-4 font-semibold">Oled konkurentidest ees</h3>
        <div class="process-widget-tab-separator"></div>
        <div class="process-widget-tab-content">
          Ärihaldustarkvara abil teed tööd efektiivsemalt ja korrastatumalt ning
          võimeka kliendiportaaliga tagad klientide rahulolu ja
          konkurentsieelise.
        </div>
      </div>
    </div>
    <div class="flex justify-center mt-8">
      <router-link
        :to="$store.state.auth.status.loggedIn ? '/calendar' : '/register'"
      >
        <button
          class="bg-turquoise hover:bg-turquoisedark hover:shadow-turquoiseoutline border-2 border-turquoise text-white font-semibold py-2 px-4 text-3xl"
        >
          Proovi TASUTA
        </button></router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: "consumer",
      email: ""
    };
  }
};
</script>

<style lang="scss">
.process-widget-tab {
  @apply flex flex-col w-1/3 justify-center items-center;
  .process-widget-tab-separator {
    @apply w-1/6 bg-green h-1 rounded-xl mt-2 mb-6;
  }
  .process-widget-tab-content {
    @apply w-9/12 mx-auto;
  }
}
@media screen and (max-width: 992px) {
  .process-widget-tab {
    @apply w-full mb-8;
  }
}
</style>

<template>
  <div
    class="flex flex-col w-9/12 medium:w-11/12 mobile:w-full mx-auto py-16 items-center"
  >
    <span class="text-5xl font-semibold mobile:text-center"
      >On veel küsimusi?</span
    >
    <span class="mb-8 mobile:px-8"
      >Edasta need meile ning vastame sulle juba üsna pea!</span
    >
    <contact-form></contact-form>
  </div>
</template>

<script>
import ContactForm from "@/components/forms/ContactForm";
export default {
  components: {
    ContactForm
  }
};
</script>

<template>
  <div class="flex flex-col py-16 bg-white">
    <h1 class="text-5xl self-center font-semibold mb-16 mobile:text-center">
      Kuidas Gewodo <span class="text-green">aitab</span>?
    </h1>
    <div class="hih-row mobile:hidden">
      <div class="hih-container">
        <div class="hih-left rounded-tl-xl mobile:rounded-none">
          <div class="flex w-full justify-center">
            <h3>Enne Gewodot...</h3>
          </div>
        </div>
        <div class="hih-right rounded-tr-xl mobile:rounded-none">
          <div class="flex w-full justify-center">
            <h3>...pärast Gewodot</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="hih-row">
      <div class="hih-chevron">
        <span class="typcn typcn-chevron-right text-6xl text-green"></span>
      </div>
      <div class="hih-container">
        <div class="hih-left">
          <div class="flex w-full">
            <div
              class="flex w-auto items-center mobile:bg-danger mobile:rounded-l-xl"
            >
              <span class="typcn typcn-times icon"></span>
            </div>
            <div class="text">
              <span></span>
              Sa kulutad aega Wordis või Excelis pakkumiste ja arvete
              tegemisele.
            </div>
          </div>
        </div>
        <div class="hih-right">
          <div class="flex w-full mobile:shadow mobile:rounded-xl">
            <div
              class="flex w-auto items-center mobile:bg-green mobile:rounded-l-xl"
            >
              <span class="typcn typcn-tick icon mobile:bg-transparent"></span>
            </div>
            <div class="text">
              <h3>Pakkumised ja arved ühes kohas</h3>
              <span
                >Loo pakkumisi ja arveid automaatsest süsteemist, talleta kõik
                Gewodosse ja loo malle, et säästa kuni 90% info sissekandmisele
                kuluvast ajast.</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hih-row">
      <div class="hih-chevron">
        <span class="typcn typcn-chevron-right text-6xl text-green"></span>
      </div>
      <div class="hih-container">
        <div class="hih-left">
          <div class="flex w-full">
            <div
              class="flex w-auto items-center mobile:bg-danger mobile:rounded-l-xl"
            >
              <span class="typcn typcn-times icon"></span>
            </div>
            <div class="text">
              Projektidega seotud info ja dokumendid on mitmes kohas laiali.
            </div>
          </div>
        </div>
        <div class="hih-right">
          <div class="flex w-full mobile:shadow mobile:rounded-xl">
            <div
              class="flex w-auto items-center mobile:bg-green mobile:rounded-l-xl"
            >
              <span class="typcn typcn-tick icon"></span>
            </div>
            <div class="text">
              <h3>Kõik ühes kohas</h3>
              <span
                >Kõik projektid, sõnumivahetused, dokumendid, pildid ja
                tööplaanid on mugavalt Gewodos koos. Nii säästad rohkelt aega
                info otsimiselt.</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hih-row">
      <div class="hih-chevron">
        <span class="typcn typcn-chevron-right text-6xl text-green"></span>
      </div>
      <div class="hih-container">
        <div class="hih-left">
          <div class="flex w-full">
            <div
              class="flex w-auto items-center mobile:bg-danger mobile:rounded-l-xl"
            >
              <span class="typcn typcn-times icon"></span>
            </div>
            <div class="text">
              Sa kulutad tunde telefonis või meile saates, et tagada klientidele
              ülevaade toimuvast.
            </div>
          </div>
        </div>
        <div class="hih-right">
          <div class="flex w-full mobile:shadow mobile:rounded-xl">
            <div
              class="flex w-auto items-center mobile:bg-green mobile:rounded-l-xl"
            >
              <span class="typcn typcn-tick icon"></span>
            </div>
            <div class="text">
              <h3>Vähem juttu, rohkem tööd</h3>
              <span
                >Kliendiportaali abil saad anda kliendile ligipääsu
                töögraafikutele ja dokumentidele, mida ta saab vaadata endale
                sobival ajal - ei mingit raporteerimist.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hih-row">
      <div class="hih-chevron">
        <span class="typcn typcn-chevron-right text-6xl text-green"></span>
      </div>
      <div class="hih-container">
        <div class="hih-left rounded-bl-xl mobile:rounded-none">
          <div class="flex w-full">
            <div
              class="flex w-auto items-center mobile:bg-danger mobile:rounded-l-xl"
            >
              <span class="typcn typcn-times icon"></span>
            </div>
            <div class="text">
              Sa kulutad tööaja ja palgalehtede kontrollimisele mitmeid tunde
              kuus.
            </div>
          </div>
        </div>
        <div class="hih-right rounded-br-xl mobile:rounded-none">
          <div class="flex w-full mobile:shadow mobile:rounded-xl">
            <div class="flex w-auto items-center mobile:bg-green rounded-l-xl">
              <span class="typcn typcn-tick icon"></span>
            </div>
            <div class="text">
              <h3>Selge ülevaade töötundidest</h3>
              <span
                >Töötajad saavad märkida oma tunde Gewodosse. Nii säästad
                rohkelt aega kontrollimiselt ja hoiad jooksvalt tööl silma
                peal.</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeView: 1,
      activeItem: null
    };
  },
  methods: {
    activateItem(item) {
      this.activeItem = item;
    },
    activateView(view) {
      this.activeItem = null;
      this.activeView = view;
    }
  }
};
</script>

<style lang="scss">
.hih-row {
  @apply flex w-full justify-center relative items-center;
  .hih-container {
    @apply flex w-7/12;
    .hih-left {
      @apply flex flex-col w-1/2 py-6 px-8 justify-center bg-danger bg-opacity-15;
      .icon {
        @apply text-3xl border-2 border-danger rounded-full leading-none text-danger;
      }
      .text {
        @apply flex w-11/12 px-4;
      }
    }
    .hih-right {
      @apply flex flex-col w-1/2 bg-green bg-opacity-15 py-6 px-8;
      .icon {
        @apply text-3xl bg-green rounded-full leading-none text-white;
      }
      .text {
        h3 {
          @apply mb-2;
        }
        @apply flex flex-col w-11/12 px-4;
      }
    }
  }
}
.hih-chevron {
  position: absolute;
  @apply leading-none;
}
@media screen and (max-width: 992px) {
  .hih-row {
    .hih-chevron {
      @apply hidden;
    }
    .hih-container {
      @apply flex-col w-full;
      .hih-left {
        @apply w-full px-2 bg-white py-2;
        .icon {
          @apply text-white border-0;
        }
        .text {
          @apply bg-danger bg-opacity-15 rounded-r-xl py-2;
        }
      }
      .hih-right {
        @apply w-full px-2 bg-white py-2;
        .icon {
          @apply bg-green;
        }
        .text {
          @apply text-left py-2 bg-green bg-opacity-15 rounded-r-xl;
        }
      }
    }
  }
}
</style>

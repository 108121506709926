<template>
  <div class="flex flex-col w-full items-center">
    <div class="flex text-sm self-center mb-4">
      Maksemeetod
    </div>
    <div class="flex bg-white rounded-full shadow mb-8">
      <div
        class="px-2  rounded-l-full py-2 pl-3 w-24 justify-center flex font-medium "
        :class="
          activeTab === 1
            ? 'rounded-r-full bg-green text-white cursor-default'
            : 'pr-2 cursor-pointer'
        "
        @click="activeTab = 1"
      >
        3 kuud
      </div>
      <div
        class="px-2 rounded-r-full py-2 pr-3 flex justify-center w-24 font-medium"
        :class="
          activeTab === 2
            ? 'rounded-l-full bg-green text-white cursor-default'
            : 'pl-2 cursor-pointer'
        "
        @click="activeTab = 2"
      >
        Aasta
      </div>
    </div>

    <div
      class="flex w-3/4 mobile:w-full px-8 medium:px-2 mobile:px-2 mobile:flex-col justify-center"
    >
      <div
        class="w-1/3 flex mobile:w-full mobile:px-2 medium:px-2 pr-3  mobile:order-2"
      >
        <div class="pricing-card">
          <span class="pricing-card-title mb-2">Baas</span>
          <span class="pricing-card-desc"
            >Pakett 1-2 töötajaga ettevõtetele, kes ei vaja töötajate haldust
            ning automatiseeritud arveid ja pakkumisi</span
          >
          <ul class="pricing-card-list">
            <li class="pricing-card-list-item">
              <span class="typcn typcn-tick w-1/12 text-xl text-green"></span>
              <span class="">Lõpmatu projektihaldus</span>
            </li>
            <li class="pricing-card-list-item">
              <span class="typcn typcn-tick w-1/12 text-xl text-green"></span>
              <span class="">Kliendihaldus</span>
            </li>
            <li class="pricing-card-list-item">
              <span class="typcn typcn-tick w-1/12 text-xl text-green"></span>
              <span class="">Dokumendihaldus</span>
            </li>
            <li class="pricing-card-list-item">
              <span class="typcn typcn-tick w-1/12 text-xl text-green"></span>
              <span class="">Märkmik</span>
            </li>
            <li class="pricing-card-list-item">
              <span class="typcn typcn-times w-1/12 text-xl text-danger"></span>
              <span class="">Meeskonnahaldus</span>
            </li>
            <li class="pricing-card-list-item">
              <span class="typcn typcn-times w-1/12 text-xl text-danger"></span>
              <span class="">Kliendiportaal</span>
            </li>
            <li class="pricing-card-list-item">
              <span class="typcn typcn-times w-1/12 text-xl text-danger"></span>
              <span class="">Arved ja pakkumised</span>
            </li>
          </ul>
          <span class="pricing-card-title mb-4 my-auto">0€/kuus</span>
        </div>
      </div>
      <div
        class="w-1/3 flex mobile:w-full mobile:px-2 medium:px-2 pl-3 mobile:mb-8"
      >
        <div class="pricing-card">
          <span class="pricing-card-title">Premium</span>
          <span
            class="w-full bg-green h-8 flex justify-center items-center mb-2 text-white font-semibold"
          >
            Soovitame
          </span>
          <span class="pricing-card-desc"
            >Pakett ettevõttele, kes soovib hallata kogu oma ettevõtte tegevust
            Gewodos lihtsalt, mugavalt ja soodsalt</span
          >
          <ul class="pricing-card-list">
            <li class="pricing-card-list-item">
              <span class="typcn typcn-tick w-1/12 text-xl text-green"></span>
              <span class="">Kõik mis baaspaketis</span>
            </li>
            <li class="pricing-card-list-item">
              <span class="typcn typcn-tick w-1/12 text-xl text-green"></span>
              <span class="">Töötajate haldus (ülesanded, suhtlus)</span>
            </li>
            <li class="pricing-card-list-item">
              <span class="typcn typcn-tick w-1/12 text-xl text-green"></span>
              <span class="">Ajamõõtmine ja tunnitabelid</span>
            </li>
            <li class="pricing-card-list-item">
              <span class="typcn typcn-tick w-1/12 text-xl text-green"></span>
              <span class="">Piiramatult töötajaid</span>
            </li>
            <li class="pricing-card-list-item">
              <span class="typcn typcn-tick w-1/12 text-xl text-green"></span>
              <span class="">Kliendiportaal</span>
            </li>
            <li class="pricing-card-list-item">
              <span class="typcn typcn-tick w-1/12 text-xl text-green"></span>
              <span class="">Arvete tegemine</span>
            </li>
            <li class="pricing-card-list-item">
              <span class="typcn typcn-tick w-1/12 text-xl text-green"></span>
              <span class="">Pakkumiste tegemine</span>
            </li>
            <li class="pricing-card-list-item">
              <span class="typcn typcn-tick w-1/12 text-xl text-green"></span>
              <span class="">Täiendavad tööriistad iga kvartal</span>
            </li>
            <li class="pricing-card-list-item">
              <span class="typcn typcn-tick w-1/12 text-xl text-green"></span>
              <span class="">10 GB salvestusruumi</span>
            </li>
          </ul>
          <span class="pricing-card-title mb-4 flex flex-col items-center">
            <span v-if="activeTab === 1" class="text-green">23€/kuus</span>
            <span v-if="activeTab === 2" class="text-green">20€/kuus</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeTab: 2,
      email: ""
    };
  }
};
</script>

<style lang="scss">
.pricing-card {
  @apply flex flex-col bg-white rounded-xl w-full shadow items-center py-4;
  .pricing-card-title {
    @apply text-3xl font-semibold;
  }
  .pricing-card-desc {
    @apply text-sm mb-4 px-8;
  }
  .pricing-card-list {
    @apply w-full flex flex-col mb-4 px-8;
    .pricing-card-list-item {
      @apply flex items-center;
    }
  }
  .pricing-card-input {
    @apply rounded-full mb-4 mx-8 flex items-center mt-auto flex-col;
  }
}
.strikethrough {
  text-decoration: line-through;
}
@media screen and(max-width: 992px) {
  .pricing-card {
    .pricing-card-list {
      @apply px-4;
      .pricing-card-list-item {
        @apply mb-4;
      }
    }
  }
}
</style>

<template>
  <div
    class="mobile:w-11/12 mobile:mx-auto w-8/12 flex flex-col mobile:pr-0 pr-8 bg-white rounded-xl"
  >
    <div class="mb-5 p-5 rounded-xl" v-if="header || text">
      <div class="flex">
        <h3>{{ header }}</h3>
      </div>
      <p>
        {{ text }}
      </p>
    </div>
    <div class="mb-3 font-semibold flex items-center" v-if="this.success">
      <img src="/bc21/check.svg" class="filter-to-green w-8 h-8 mr-4" />
      <span>Sõnum edukalt saadetud! Vastame ühe tööpäeva jooksul!</span>
    </div>
    <div class="error-box mb-3 flex-col flex shadow" v-if="$v.$error">
      <span v-if="!$v.name.required">Palun sisestage nimi</span>
      <span v-if="!$v.email.required">Palun sisestage meiliaadress</span>
      <span v-if="!$v.email.email">Meiliaadressi ei vasta nõuetele</span>
      <span v-if="!$v.message.required">Palun sisestage sõnum</span>
    </div>
    <div class="w-full flex flex-col bg-white rounded-xl">
      <div class="flex mobile:flex-col mb-5">
        <div
          class="form-group mobile:w-full w-6/12 mobile:mb-5 pr-2 mobile:pr-0"
        >
          <div class="flex leading-none items-center">
            <h4 class="ml-2">
              Nimi
            </h4>
            <span class="text-2xl leading-none ml-1">*</span>
          </div>
          <input
            class="w-full bg-offwhite"
            v-model="name"
            :class="
              $v.$error && $v.name.$invalid
                ? 'shadow-dangeroutline focus:shadow-dangeroutline'
                : ''
            "
          />
        </div>
        <div class="form-group mobile:w-full w-6/12 pl-2 mobile:pl-0">
          <h4 class="ml-2">Telefoninumber</h4>
          <input class="w-full bg-offwhite" v-model="phoneNumber" />
        </div>
      </div>
      <div class="form-group w-full">
        <div class="flex leading-none items-center">
          <h4 class="ml-2">
            Meiliaadress
          </h4>
          <span class="text-2xl ml-1">*</span>
        </div>
        <input
          class="mb-5 bg-offwhite"
          v-model="email"
          :class="
            $v.$error && $v.email.$invalid
              ? 'shadow-dangeroutline focus:shadow-dangeroutline'
              : ''
          "
        />
      </div>
      <div class="form-group w-full">
        <div class="flex leading-none items-center">
          <h4 class="ml-2">
            Sõnumi sisu
          </h4>
          <span class="text-2xl leading-none ml-1">*</span>
        </div>
        <textarea
          class="mb-8 bg-offwhite"
          v-model="message"
          :class="
            $v.$error && $v.message.$invalid
              ? 'shadow-dangeroutline focus:shadow-dangeroutline'
              : ''
          "
          rows="6"
        ></textarea>
      </div>
      <button class="new-button-green self-center" @click="sendContactEmail()">
        <span class="icon typcn typcn-tick"></span>
        <span class="label">Saada</span>
      </button>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import RequestHandler from "@/assets/mixins/RequestHandler";

export default {
  props: {
    header: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      name: "",
      email: "",
      phoneNumber: "",
      message: "",
      success: false
    };
  },
  mixins: [RequestHandler],
  methods: {
    sendContactEmail() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        const data = {
          sender_name: this.name,
          sender_email: this.email,
          telephone_number: this.phoneNumber,
          message: this.message,
          origin_form: "contact"
        };
        this.apiRequest("core/email/", "post", false, data).then(res => {
          if (res.status === 201) {
            this.success = true;
          }
        });
      }
    }
  },
  mounted() {
    if (this.$store.state.userData.user.display_username) {
      this.name = this.$store.state.userData.user.display_username;
    }
    if (this.$store.state.userData.user.telephone_number) {
      this.phoneNumber = this.$store.state.userData.user.telephone_number;
    }
    if (this.$store.state.userData.user.email) {
      this.email = this.$store.state.userData.user.email;
    }
  },
  validations: {
    name: {
      required
    },
    email: {
      required,
      email
    },
    message: {
      required
    }
  }
};
</script>

<template>
  <div class="flex flex-col bg-offwhite items-center py-16">
    <span class="text-5xl font-semibold text-center"
      ><span class="text-green">Ülisoodne</span>.</span
    >
    <span class="text-5xl font-semibold mb-8 text-center">Loodud sulle.</span>
    <pricing-widget></pricing-widget>
    <router-link to="/pricing">
      <button
        class="bg-green text-white font-medium hover:bg-darkgreen hover:shadow-outline text-2xl mx-16 mt-8 py-4 px-5"
        v-if="$route.name !== 'Pricing'"
        @click="$router.push('/pricing')"
      >
        Vaata täpsemalt
      </button>
    </router-link>
  </div>
</template>

<script>
import PricingWidget from "./pricing/PricingWidget";
export default {
  components: {
    PricingWidget
  }
};
</script>
